var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "div",
            { staticClass: "w-full flex gap-2 items-center justify-end pt-4" },
            [
              _c("CartDropDown", {
                attrs: { cartItems: _vm.cart.items },
                on: { updateCartHandling: _vm.updateCartHandling },
              }),
              _c("CartResumeSideBar", {
                attrs: { cartItems: _vm.cart.items },
                on: { updateCartHandling: _vm.updateCartHandling },
              }),
            ],
            1
          ),
          _c(
            "vs-tabs",
            { key: _vm.tabsCorporateSalesKey },
            [
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("product.title") } },
                [
                  _c("ProductGridCardView", {
                    attrs: { corporate_sale: true },
                    on: { updateCartHandling: _vm.updateCartHandling },
                  }),
                ],
                1
              ),
              _vm.isOpenCart
                ? _c(
                    "vs-tab",
                    {
                      attrs: {
                        label: _vm.$t("marketing_place.purchase_summary"),
                      },
                    },
                    [
                      _c("CartResume", {
                        attrs: {
                          cartItems: _vm.cart.items,
                          removeItemEnable: true,
                        },
                        on: { updateCartHandling: _vm.updateCartHandling },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isOpenCart
                ? _c(
                    "vs-tab",
                    { attrs: { label: _vm.$t("marketing_place.payment") } },
                    [
                      _c("FormPayment", {
                        attrs: {
                          cart_id: _vm.cartId,
                          valueTotal: _vm.valueTotal,
                        },
                        on: {
                          updateCartHandling: _vm.updateCartHandlingOfPayment,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }